import getConfig from 'next/config';
import { SWRConfig } from 'swr';
import '../styles/global.scss';
import '@popperjs/core';
import { UserProvider } from '../components/userContext';
import '../styles/nprogress.css';
import { fetcher } from '../components/utils';
import { useEffect } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import TagManager from 'react-gtm-module';

Router.events.on('routeChangeStart', (url) => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// Client-side / public config
const clientSideConfig = getConfig();

export default function App({ Component, pageProps }) {
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-M7XM3G5' });
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <SWRConfig
            value={{
                fetcher: fetcher,
                dedupingInterval: clientSideConfig.publicRuntimeConfig.swrDedupingInterval,
                refreshInterval: clientSideConfig.publicRuntimeConfig.swrRefreshInterval,
                revalidateOnFocus: clientSideConfig.publicRuntimeConfig.swrRevalidateOnFocus,
            }}>
            <UserProvider>
                <Component {...pageProps} />
            </UserProvider>
        </SWRConfig>
    );
}
